<template>
  <v-card class="create-card" flat>
    <UserInfoCard />
    <SignatureLevelCards />
    <Signatures class="mt-3" />
  </v-card>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.create-card {
  box-sizing: border-box;
  background-color: white;
  margin-bottom: 4px;
  margin-top: 16px;
}

.signee-email {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.0025em;
  color: black !important;
  padding-bottom: 10px;
}

.signature-level {
  color: black;
  font-weight: 400 !important;
  margin-right: 6px;
  font-size: 14px !important;
}

.actions-container {
  width: 384px;
  margin: 0 16px 16px 16px !important;
}
</style>
