import SignatureService from '@/services/signatureService';
import AuthService from '@/services/auth-service';
import i18n from '@/plugins/i18n';
import { DocumentStatus } from '@/types/enums/DocumentStatus';
import { SignStatus } from '@/types/enums/SignStatus';
import { useDownloadStore } from '@/stores/download/download';
import {
  cloneDeep,
  groupBy,
  downloadFile,
  downloadFilesAsZip
} from '@/composables/useUtils';
import type { Document } from '@/types/Document';
import type { Signee } from '@/types/Signee';
import type { Observer } from '@/types/Observer';
import type { OverviewDocument } from '@/stores/overview/overview';
import { SignatureType } from '@/types/enums/SignatureType';

export function getCurrentStatusPage(
  userEmail: string,
  document: Document
): string {
  switch (document.documentStatus) {
    case DocumentStatus.DRAFT:
      return `/document/${document.documentId}/create`;
    case DocumentStatus.IN_PROGRESS:
      if (!SignatureService.shouldISign(userEmail, document?.signees ?? [])) {
        return `/document/${document.documentId}/details`;
      }
      if (SignatureService.isSignedByUser(userEmail, document?.signees ?? [])) {
        return `/document/${document.documentId}/details`;
      } else {
        return `/document/${document.documentId}/sign`;
      }
    case DocumentStatus.WITHDRAWN:
      return `/document/${document.documentId}/details`;
    default:
      return `/document/${document.documentId}/details`;
  }
}

export function getCurrentStatusPageFromOverview(document: OverviewDocument) {
  if (!document) return undefined;
  switch (document?.documentStatus) {
    case DocumentStatus.DRAFT:
      return `/document/${document.documentId}/create`;
    case DocumentStatus.IN_PROGRESS:
      if (!SignatureService.shouldISignFromOverview(document)) {
        return `/document/${document.documentId}/details`;
      }
      if (SignatureService.isSignedByUserFromOverview(document)) {
        return `/document/${document.documentId}/details`;
      } else {
        return `/document/${document.documentId}/sign`;
      }
    case DocumentStatus.WITHDRAWN:
      return `/document/${document.documentId}/details`;
    default:
      return `/document/${document.documentId}/details`;
  }
}

export async function downloadDocumentPdf(
  signKey: string,
  documentName: string,
  documentStatus: DocumentStatus,
  documentId: string
) {
  const downloadStore = useDownloadStore();
  if (signKey) {
    // Signees
    if (AuthService.isAuthenticated) {
      await downloadStore.fetchAuthDocumentDownloadUrl(signKey);
    } else {
      await downloadStore.fetchDocumentDownloadUrl(signKey);
    }
    downloadFile(downloadStore.documentDownloadUrl, documentName);
  } else if (documentStatus === DocumentStatus.DRAFT) {
    // Initiator
    await downloadStore.fetchOwnerDocumentDownloadUrl(documentId);
    downloadFile(downloadStore.documentDownloadUrl, documentName);
  } else {
    // Observer
    await downloadStore.fetchObserverDocumentDownloadUrl(documentId);
    downloadFile(downloadStore.documentDownloadUrl, documentName);
  }
}

export async function downloadAllDocumentPdfs(
  urls: string[],
  fileNames: string[]
) {
  try {
    await downloadFilesAsZip(urls, fileNames);
  } catch (error) {
    console.error('There was an error downloading all the documents');
  }
}

export function getStatusColor(document: Document | OverviewDocument): string {
  const colors = {
    toSign: 'blue-darken-3',
    toSeal: 'cyan-darken-3',
    pending: 'orange-darken-4',
    declined: 'grey-darken-2',
    completed: 'green-darken-2',
    draft: 'deep-purple-darken-2',
    withdrawn: 'grey-darken-2',
    rejected: 'grey-darken-2'
  };

  if (document.documentStatus === DocumentStatus.SIGNED) {
    return colors.completed;
  }

  if (document.documentStatus === DocumentStatus.DRAFT) {
    return colors.draft;
  }

  if (document.documentStatus === DocumentStatus.WITHDRAWN) {
    return colors.withdrawn;
  }

  if (document.documentStatus === DocumentStatus.REJECTED) {
    return colors.rejected;
  }

  if (document.documentStatus === DocumentStatus.IN_PROGRESS) {
    if (document.signStatus === SignStatus.PENDING) {
      if (document.signatureType === SignatureType.SIGNATURE) {
        return colors.toSign;
      } else {
        return colors.toSeal;
      }
    } else return colors.pending;
  }

  return '';
}

export function getStatusText(document: Document | OverviewDocument) {
  const texts = {
    toSign: i18n.global.t('document_status.to_sign'),
    toSeal: i18n.global.t('document_status.to_seal'),
    pending: i18n.global.t('document_status.pending'),
    declined: i18n.global.t('document_status.declined'),
    completed: i18n.global.t('document_status.completed'),
    draft: i18n.global.t('document_status.draft'),
    withdrawn: i18n.global.t('document_status.withdrawn'),
    rejected: i18n.global.t('document_status.rejected')
  };

  if (document.documentStatus === DocumentStatus.SIGNED) {
    return texts.completed;
  }

  if (document.documentStatus === DocumentStatus.DRAFT) {
    return texts.draft;
  }

  if (document.documentStatus === DocumentStatus.WITHDRAWN) {
    return texts.withdrawn;
  }

  if (document.documentStatus === DocumentStatus.REJECTED) {
    return texts.rejected;
  }

  if (document.documentStatus === DocumentStatus.IN_PROGRESS) {
    if (document.signStatus === SignStatus.PENDING) {
      if (document.signatureType === SignatureType.SIGNATURE) {
        return texts.toSign;
      } else {
        return texts.toSeal;
      }
    }
    return texts.pending;
  }

  return '';
}

export function getUpdateOrderRequestFromSignGroups(signGroups: Signee[][]) {
  const requestBody: string[][] = [];
  signGroups.forEach((group) => {
    const signeeIds: string[] = [];
    group.forEach((signee: Signee) => {
      signeeIds.push(signee.signeeId);
    });
    requestBody.push(signeeIds);
  });
  return requestBody.length === 1 ? requestBody[0] : requestBody;
}

export function getSignGroupsFromSignees(signeesList: Signee[]) {
  const signeeGroups: Signee[][] = [];
  let signees: Signee[] = cloneDeep(signeesList);
  let groupIndex = 0;
  signees = signees.sort((a, b) => {
    return a.signOrder - b.signOrder;
  });

  const groupedSignees = groupBy(signees, 'signOrder');
  for (const value of Object.values(groupedSignees)) {
    signeeGroups.push([]);
    value.forEach((signee) => {
      signeeGroups[groupIndex].push(signee);
    });
    groupIndex++;
  }
  return signeeGroups;
}

export function getRequestBodyFromSignGroups(signGroups: Signee[][]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestBody: any = [];
  if (!signGroups) {
    return requestBody;
  }
  if (signGroups.length === 1) {
    signGroups[0].forEach((signee) => {
      requestBody.push([signee.signeeId]);
    });
    return requestBody;
  } else {
    signGroups.forEach((group) => {
      const signeeIds: string[] = [];
      group.forEach((signee) => {
        signeeIds.push(signee.signeeId);
      });
      requestBody.push(signeeIds);
    });
    return requestBody;
  }
}

export function getRequestBodyFromSignees(signeesList: Signee[]) {
  return getRequestBodyFromSignGroups(getSignGroupsFromSignees(signeesList));
}

export function isOrdered(document: Document) {
  return document?.signeesOrdered?.length > 1;
}

export function hasExpertSettingsDefined(document: Document) {
  return hasDocumentObservers(document) || document.signeeAttachmentsAllowed;
}

export function hasDocumentObservers(document: Document) {
  return document?.observers?.length > 0 || false;
}

export function observersToDelete(
  documentObservers: Observer[],
  formObservers: Observer[]
) {
  const toDelete: string[] = [];
  documentObservers.forEach((obs) => {
    const index = formObservers.findIndex(
      (formObs) => formObs.observerId === obs.observerId
    );
    if (index === -1) {
      toDelete.push(obs.observerId);
    }
  });
  return toDelete;
}

export function observersToUpdate(
  documentObservers: Observer[],
  formObservers: Observer[]
) {
  const toUpdate: string[] = [];
  formObservers.forEach((formObs) => {
    const docObs = documentObservers.find(
      (docObs) => docObs.observerId === formObs.observerId
    );
    if (
      (docObs?.observerId && docObs?.email !== formObs?.email) ||
      (docObs?.observerId && docObs?.isAdmin !== formObs?.isAdmin)
    ) {
      toUpdate.push(formObs.observerId);
    }
  });
  return toUpdate;
}
