<template>
  <v-layout class="full-viewport-height">
    <DocumentDrawer
      v-if="
        !showMobileFooter &&
        currentDocument &&
        showDocument &&
        !isDocumentValidationView
      "
    />

    <DocumentToolbar
      v-if="showDocument && currentDocument"
      :document="currentDocument"
    />

    <v-main>
      <v-container class="container-wrapper" fluid>
        <router-view />
      </v-container>
      <SignatureRejectDialog />
      <GuestSendEmailDialog />
      <DeleteDocumentsDialog />
      <DocumentWithdrawDialog />
      <MobileFooter
        v-if="showMobileFooter && currentDocument && showDocument"
      />
    </v-main>
  </v-layout>
</template>

<script setup lang="ts">
import { useDocumentStore } from '@/stores/document/document';
import { useSignStore } from '@/stores/sign/sign';
import { computed, onUnmounted } from 'vue';
import { useSubscriptionStore } from '@/stores/subscription/subscription';
import { useMachine } from 'xstate-vue2';
import { inviteMachine } from '@/machines/inviteMachine';
import AuthService from '@/services/auth-service';
import { useRoute } from 'vue-router';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useSignContext } from '@/composables/useSignContext';
import { useDisplay } from 'vuetify';

const documentStore = useDocumentStore();
const signStore = useSignStore();
const subscriptionStore = useSubscriptionStore();
const display = useDisplay();

onUnmounted(() => {
  documentStore.document = undefined;
  signStore.signInfo = null;
  subscriptionStore.hasValidSubscription = false;
});

const { signKey } = useSignContext();
const { currentDocument } = useDocumentContext();
const route = useRoute();

const isInvite = computed(() => {
  return route.name === 'sign-id';
});

const isDocumentValidationView = computed(
  () =>
    route.name === 'document-validate' || route.name === 'document-validate-new'
);

const showMobileFooter = computed(() => {
  return display.mdAndDown.value;
});

const { state: inviteState } = useMachine(inviteMachine, {
  context: {
    isInvite: isInvite.value,
    invitationLink: window.location.href,
    user: AuthService.user,
    info: signStore.signKeyInfo,
    signKey: signKey.value,
    signStore: signStore
  }
});

const showDocument = computed(() => {
  return inviteState.value.matches('document.allowed');
});
</script>

<style lang="scss">
.container-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.v-main {
  padding-bottom: 0% !important;
}
</style>
