<template>
  <div class="px-2">
    <div
      class="w-full bg-white shadow-md border border-black/15 h-14 rounded-md flex items-center justify-between p-2"
    >
      <div>
        <VBtn
          v-tooltip:bottom="i18n.t('text_unselect_all')"
          icon
          size="x-small"
          class="ml-1"
          @click="emit('clear-selection')"
        >
          <VIcon icon="fa-regular fa-xmark" class="text-gray-600" />
        </VBtn>
        <span class="ml-4">
          {{ i18n.t('text.document', { n: total }) }}
        </span>
      </div>
      <div class="flex gap-2 items-center">
        <VBtn
          v-for="secondaryAction in secondaryActions"
          :key="secondaryAction.label"
          v-tooltip:bottom="secondaryAction.label"
          variant="tonal"
          :color="secondaryAction.type === 'warning' ? 'red' : undefined"
          icon
          size="small"
          @click="secondaryAction.action"
        >
          <VIcon
            :icon="secondaryAction.icon ? secondaryAction.icon : undefined"
          />
        </VBtn>

        <VBtn
          class="hidden lg:flex"
          :color="mainAction.type === 'warning' ? 'error' : 'primary'"
          :prepend-icon="mainAction.icon"
          data-test-id="batch-sign"
          @click.prevent="() => mainAction.action()"
        >
          {{ mainAction.label }}
        </VBtn>
        <VBtn
          v-tooltip:bottom="mainAction.label"
          class="block lg:hidden"
          :color="mainAction.type === 'warning' ? 'error' : 'primary'"
          icon
          size="small"
          :aria-label="mainAction.label"
          @click.prevent="() => mainAction.action()"
        >
          <v-icon :icon="mainAction.icon" />
        </VBtn>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useDocumentActions } from '@/composables/useDocumentActions';
import { useRouteQuery } from '@vueuse/router';
import type {
  FilterDocumentStatuses,
  FilterSignStatuses
} from '@/types/OverviewFilter';
import { computed, inject } from 'vue';

const i18n = useI18n();

const filterSignStatus = useRouteQuery<FilterSignStatuses>('filterSignStatus');
const filterDocumentStatus = useRouteQuery<FilterDocumentStatuses>(
  'filterDocumentStatus'
);

const selectedDocuments = inject('selectedDocuments');

const emit = defineEmits(['clear-selection']);

defineProps({
  total: {
    type: Number,
    required: true
  }
});

function getActions() {
  const { actions } = useDocumentActions(
    Array.isArray(filterDocumentStatus.value)
      ? filterDocumentStatus.value[0]
      : filterDocumentStatus.value,
    Array.isArray(filterSignStatus.value)
      ? filterSignStatus.value[0]
      : filterSignStatus.value,
    selectedDocuments
  );
  return actions.value;
}

const mainAction = computed(() => {
  return getActions()[0];
});
const secondaryActions = computed(() => {
  return getActions().slice(1);
});
</script>
